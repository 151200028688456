import revive_payload_client_4sVQNw7RlN from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/.nuxt/formkitPlugin.mjs";
import gsap_client_YCLjR5XmPz from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/plugins/gsap.client.ts";
import vue_plyr_client_sjaH772cn2 from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/plugins/vue-plyr.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  formkitPlugin_pZqjah0RUG,
  gsap_client_YCLjR5XmPz,
  vue_plyr_client_sjaH772cn2
]